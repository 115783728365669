import {Component, HostListener, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {fromEvent, Subscription} from "rxjs";

enum WidgetType {
  Alert = "alert",
  Goal = "goal",
  Top = "top"
}

interface WidgetMeta {
  index: number;
  icon: string;
  uri: SafeResourceUrl;
}

@Component({
  selector: "app-widget-preview",
  templateUrl: "./widget-preview.component.html",
  styleUrls: ["./widget-preview.component.scss"]
})
export class WidgetPreviewComponent implements OnInit {
  public selectedLayoutIndex = 0;
  public readonly widgetsMeta: WidgetMeta[] = null;

  private loadedFramesCounter = 0;
  private readonly widgetType: WidgetType = null;

  public constructor(
      route: ActivatedRoute,
      private readonly sanitizer: DomSanitizer) {
    this.widgetType = route.snapshot.data.widget;
    this.widgetsMeta = this.getWidgetsMeta();
  }

  public ngOnInit(): void {
  }

  public onSetLayout(index: number) {
    this.selectedLayoutIndex = index;
  }

  private formatWidgetUri(type: WidgetType, ref: string, token: string): SafeResourceUrl {
    let uri = "https://widgets.staging.donatty.com/";

    switch (type) {
      case WidgetType.Alert: uri += "donations"; break;
      case WidgetType.Goal: uri += "goal"; break;
      case WidgetType.Top: uri += "stats"; break;
    }

    uri += `/?ref=${ref}&token=${token}&api=https://api.staging.donatty.com&logLevel=trace&flags=preview,centered`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(uri);
  }

  private getWidgetsMeta(): WidgetMeta[] {
    switch (this.widgetType) {
      case WidgetType.Alert:
        return [
          {
            icon: "alert-0",
            index: 0,
            uri: this.formatWidgetUri(
              WidgetType.Alert,
              "4bc4d0da-d2c8-440c-b2e3-ca1f441e8e8e",
              "HkLJoBmChTFdyDtjmEfqMkUUd8PSjd")
          },
          {
            icon: "alert-1",
            index: 1,
            uri: this.formatWidgetUri(
              WidgetType.Alert,
              "db53b104-2ea6-4deb-b859-9f9c909ff318",
              "lTQbfx0s5X3FzuiG2YqSemd8vRnzz0")
          },
          {
            icon: "alert-2",
            index: 2,
            uri: this.formatWidgetUri(
              WidgetType.Alert,
              "7e72ec5d-40db-4f0e-be9f-e3c6fafe6390",
              "grDOXoTiv7H6LGBMqeOct8hiE5YtP0")
          },
        ];

      case WidgetType.Goal:
        return [
          {
            icon: "goal-0",
            index: 0,
            uri: this.formatWidgetUri(
              WidgetType.Goal,
              "c721da67-ddc8-4c36-8a58-51a6e050ac83",
              "UjSaMOdSS3VsBjUbmuMCN1M8fx53b1")
          },
          {
            icon: "goal-1",
            index: 1,
            uri: this.formatWidgetUri(
              WidgetType.Goal,
              "0102b33b-9bdb-41d8-b442-b571648ff967",
              "dHs9tn4auJF1bjyXWrJ3PhRBYCAqXz")
          },
          {
            icon: "goal-2",
            index: 2,
            uri: this.formatWidgetUri(
              WidgetType.Goal,
              "25417c9e-4663-4622-b0d2-00f5a89de0f4",
              "giu7vsrotKP6WzM7wMv10CQuAwiaZ0")
          },
        ];

      case WidgetType.Top:
        return [
          {
            icon: "top-0",
            index: 0,
            uri: this.formatWidgetUri(
              WidgetType.Top,
              "8a829833-e7eb-418d-baab-3dfc9158833c",
              "L26AE9zMNGCUFsVyLGdz74UA5gu1z0")
          },
          {
            icon: "top-1",
            index: 1,
            uri: this.formatWidgetUri(
              WidgetType.Top,
              "20228db1-1ab9-4beb-80d5-ece4a8590877",
              "gMyHwbwsym7nEAzyZKsSU4UOnyzsSd")
          },
          {
            icon: "top-2",
            index: 2,
            uri: this.formatWidgetUri(
              WidgetType.Top,
              "6715b33e-3f4d-4886-ac6f-ceb51012de00",
              "e6JIcj0cQGYAAqyKnL9qwEuG3vW2Gz")
          },
        ];
    }

    throw new Error(`unexpected widget type ${this.widgetType}`);
  }

  private initAlertWidgets(): void {
    const initializationData = {
      action: "DATA",
      data: {
        subscriber: "Подписчик",
        message: "А вот и я - головка от хуя",
        amount: 1500,
        mute: {
          audio: true,
          voice: true
        }
      }
    };

    const frames = document.querySelectorAll<HTMLIFrameElement>("iframe");
    frames.forEach(frame => {
      frame.contentWindow.postMessage(initializationData, "*");
    });
  }

  private initGoalWidgets(): void {
    const initializationData = {
      action: "DATA",
      data: {
        raised: 150000
      }
    };

    const frames = document.querySelectorAll<HTMLIFrameElement>("iframe");
    frames.forEach(frame => {
      frame.contentWindow.postMessage(initializationData, "*");
    });
  }

  private initTopWidgets(): void {
    const initializationData = {
      action: "DATA",
      data: {
        list: [
          { name: "Finn the Human", value: 420000 },
          { name: "Rick Sanchez", value: 380000 },
          { name: "Jake the Dog", value: 320000 },
          { name: "Morty Smith", value: 314000 },
          { name: "Princess Bubblegum", value: 300000 },
          { name: "Summer Smith", value: 299999 },
          { name: "Beth Smith", value: 291723 },
          { name: "Jerry Smith", value: 290000 },
          { name: "Marceline the Vampire Queen", value: 280000 },
          { name: "Ice King", value: 100000 },
          { name: "Scrooge McDuck", value: 99999},
          { name: "Huey", value: 69000},
          { name: "BMO", value: 4200 },
          { name: "Dewey", value: 3100},
          { name: "Louie", value: 1800},
          { name: "Lumpy Space Princess", value: 1069 },
          { name: "Flame Princess", value: 666 },
          { name: "Launchpad McQuack", value: 500},
          { name: "Duckworth the Butler", value: 220},
          { name: "Lady Rainicorn", value: 123 },
        ]
      }
    };

    const frames = document.querySelectorAll<HTMLIFrameElement>("iframe");
    frames.forEach(frame => {
      frame.contentWindow.postMessage(initializationData, "*");
    });
  }

  private initWidgets(): void {
    switch (this.widgetType) {
      case WidgetType.Alert:
        this.initAlertWidgets();
        break;

      case WidgetType.Goal:
        this.initGoalWidgets();
        break;

      case WidgetType.Top:
        this.initTopWidgets();
        break;
    }
  }

  private setFrameScale(): void {
    const widgetsContainer = document.querySelector(".wp__widget");
    const widgetsContainerRect = widgetsContainer.getBoundingClientRect();

    const widgetWrapper = document.querySelector(".wp__widget-wrapper");
    const widgetWrapperRect = widgetWrapper.getBoundingClientRect();

    const aspectX = Math.min(1, widgetsContainerRect.width / widgetWrapperRect.width);
    const aspectY = Math.min(1, widgetsContainerRect.height / widgetWrapperRect.height);
    const aspect = Math.min(aspectX, aspectY);

    const frames = document.querySelectorAll<HTMLIFrameElement>("iframe");
    frames.forEach(frame => {
      frame.style.transform = `scale(${aspect})`;
    });
  }

  @HostListener("window:message", ["$event"])
  public onWindowMessage(event: MessageEvent): void {
    if (!event.data) {
      return;
    }

    let data = null;

    try {
      data = JSON.parse(event.data);
    } catch (e) {
      return;
    }

    if (!data.type) {
      return;
    }

    console.log("ZZZZZZZZZZZ", data);

    if (data.type === "request-dub") {
      this.playDubSample(data.voice.value);

      return;
    }

    if (data.type === "widget.loaded") {
      ++this.loadedFramesCounter;

      if (this.loadedFramesCounter < 3) {
        return;
      }

      this.initWidgets();
      this.setFrameScale();
    }
  }

  private playDubSample(voice: string): void {
    const text = "Пупа и Лупа доедали новогодние салаты. " +
      "Они договорились разделить их поровну и Лупа доедал крабовый за Пупой, " +
      "а Пупа доедал оливье за Лупой.";
    const ttsUri = `https://api.staging.donatty.com/donations/tts?text=${text}&speed=1&voice=${voice}`;
    const initializationData = {
      action: "DATA",
      data: {
        subscriber: "Озвучка",
        message: text,
        amount: 500,
        mute: {
          audio: true,
          voice: false
        },
        tts: ttsUri
      }
    };

    console.log(`playing sample ${ttsUri}`);

    const frames = document.querySelectorAll<HTMLIFrameElement>("iframe");
    frames[0].contentWindow.postMessage(initializationData, "*");
  }
}
