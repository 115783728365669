import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {WidgetPreviewComponent} from "./widget-preview/widget-preview.component";
import {DubbingPreviewComponent} from "./dubbing-preview/dubbing-preview.component";
import {DropdownComponent} from "./dropdown/dropdown.component";

@NgModule({
  declarations: [
    AppComponent,
    WidgetPreviewComponent,
    DubbingPreviewComponent,
    DropdownComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
