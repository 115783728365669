import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {WidgetPreviewComponent} from "./widget-preview/widget-preview.component";
import {DubbingPreviewComponent} from './dubbing-preview/dubbing-preview.component';

const routes: Routes = [
  {
    path: "alert",
    component: WidgetPreviewComponent,
    data: { widget: "alert" }
  },
  {
    path: "top",
    component: WidgetPreviewComponent,
    data: { widget: "top" }
  },
  {
    path: "goal",
    component: WidgetPreviewComponent,
    data: { widget: "goal" }
  },
  {
    path: "dubbing",
    component: DubbingPreviewComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
