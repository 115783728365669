import {Component, OnInit, ViewChild} from '@angular/core';
import {DropdownComponent} from '../dropdown/dropdown.component';

@Component({
  selector: "app-dubbing-preview",
  templateUrl: "./dubbing-preview.component.html",
  styleUrls: ["./dubbing-preview.component.scss"]
})
export class DubbingPreviewComponent implements OnInit {
  public readonly VOICES = [
    { value: "oksana", text: "Оксана" },
    { value: "zahar", text: "Захар" },
    { value: "jane", text: "Аня" },
    { value: "ermil", text: "Виктор" },
    { value: "omazh", text: "Елена" },
    { value: "silaerkan", text: "Сулейман" },
  ];

  @ViewChild("voice", { static: true })
  private voiceDropdown: DropdownComponent;

  public constructor() {
  }

  public ngOnInit() {
  }

  public onPlayPreview(evt: Event): void {
    evt.stopImmediatePropagation();
    evt.preventDefault();

    parent.postMessage({
      type: "request-dub",
      voice: this.voiceDropdown.value
    }, "*");
  }

}
