<div class="dub__controls">
  <div class="dub__label">Выбрать голос</div>
  <div class="dub__dropdown">
    <dropdown #voice [items]="VOICES" [value]="VOICES[0]"></dropdown>
  </div>
  <div class="dub__play" (click)="onPlayPreview($event)">
    <span>Проиграть пример</span>
    <img src="/assets/icons/play.svg" />
  </div>
</div>
