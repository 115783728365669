import {Component, Input, Output, EventEmitter, HostListener, ElementRef} from "@angular/core";

@Component({
  selector: "dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"]
})
export class DropdownComponent {

  @Input()
  set value(v: any) {
    this._value = v && v.text
      ? v : this.items.find(it => it.value == v);
  }

  get value(): any {
    return this._value;
  }

  @Input() items = [];
  @Output() valueChange = new EventEmitter();

  opened = false;

  private _value;

  constructor(
    private thisRef: ElementRef) {
  }

  select(item) {
    this.value = item;
    this.opened = false;
    this.valueChange.emit(this.value);
  }

  click(e: Event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    this.opened = true;
  }

  @HostListener("document:click", ["$event"])
  documentClick(event: MouseEvent) {
    if (!this.thisRef.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }
}
