<div class="wp__root">
  <div class="wp__widget">
    <div class="wp__widget-wrapper"
         *ngFor="let w of widgetsMeta"
         [ngClass]="{'wp__visible': (this.selectedLayoutIndex === w.index)}">
      <iframe [src]="w.uri"></iframe>
    </div>
  </div>
  <div class="wp__buttons">
    <div *ngFor="let w of widgetsMeta"
         class="wp__button"
         [ngClass]="{'wp__button--selected': (this.selectedLayoutIndex === w.index)}"
         (click)="onSetLayout(w.index)">
      <img src="/assets/icons/{{w.icon}}.svg" />
      <img src="/assets/icons/{{w.icon}}.selected.svg"
           class="wp__selected-icon" />
    </div>
  </div>
</div>
