<div class="we__dropdown">
  <div class="we__collapsed" (click)="click($event)" [ngClass]="{'visible': !opened}">
    <div class="we__value" *ngIf="value">
      {{value.text}}
    </div>
    <div class="we__icon">
      <img src="/assets/icons/dropdown-open.svg">
    </div>
  </div>
  <div class="we__opened-base-layer" [ngClass]="{'visible': opened}">
    <div class="we__opened">
      <div class="we__option"
           (click)="select(item)"
           [ngClass]="{selected: (value === item)}"
           *ngFor="let item of items">
        <div class="we__value">
          {{item.text}}
        </div>
        <div class="we__selected-icon">
          <img src="/assets/icons/dropdown-selected.svg" />
        </div>
      </div>
    </div>
  </div>
</div>
